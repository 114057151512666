.container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container .innerContainer {
  max-width: 450px;
  margin-top: 100px;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
}

.container .innerContainer img {
  width: 60%;
}

.container .innerContainer input {
  border: 1px solid #ebedef;
  border-radius: 0.25rem;
  line-height: 1.5;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0.375rem 0.35rem;
  background: #fff;
  color: #444d56;
  font-size: 3em;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(197, 197, 197);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(197, 197, 197);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(197, 197, 197);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(197, 197, 197);
}

.button {
  display: block;
  padding: 0.75rem 2rem;
  margin: 20px 0px 0px 0px;
  background-color: #25b401;
  color: #fff;
  font-family: inherit;
  font-weight: inherit;
  border-radius: 0 25px 25px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

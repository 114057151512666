.container {
  padding: 20px;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.inner {
  max-width: 1000px;
  width: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
}

.iframe {
  background-color: white;
  padding: 20px;
}
